.ser-select-sede-body  {
  position: relative;
  display: flex;
}

.ser-select-sede-body .content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 502px;
  padding: 20px;
  box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.2);
  margin: auto;
  z-index: 3;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .ser-select-sede-body .content {
    width: 100%;
  }
}

.ser-select-sede-body .backgound {
  position: absolute;
  background-color: #f0f0f0;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 83%;
  z-index: 1;
}

.ser-select-sede-body .title {
  text-align: center;
  color: #21406d;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 15px;
}

.ser-select-sede-body .form-group { 
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ser-select-sede-body .label {
  color: #c1cb48;
  font-weight: 600;
}

/* svg {
  height: 25px;
  width: 25px;
} */